.notice {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 5000;
  padding: 15px 25px 5px 0;
  height: auto;
  max-width: 40%;
  border-radius: 5px;
  color: white;
  top: 15px;
  left: 20px;
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  animation: scale-up-center 0.4s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.notice-button {
  border: none;
  background-color: #fff0;
  color: #fff;
  position: relative;
  right: -18px;
  margin-bottom: 1rem;
}

li {
  list-style: none;
}